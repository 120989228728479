
<template>
    <div class="summary-student-info content-bg" :class="{'page-view': pageType == 'view'}">
        <global-page-back detailPageTitle="学生档案" @handlerGlobalType="back">
            <div
                v-if="pageType == 'view'"
                slot="globalPageBackTabs"
                style="display:flex;align-items:center;">
                <div style="width:1px; height:24px;background-color:#D4D6D9;margin: 0 28px 0 58px;"></div>
                <!-- <el-button type="primary" @click="firstExportBtn()">导出pdf</el-button> -->
            </div>
            <div
                v-if="pageType != 'view'"
                slot="globalPageBackTabs"
                class="header-tabs">
                <div class="header-tab" :class="{ 'header-tab_active': editType == 'base' }" @click="handlerEditTypeChange('base')">基础信息</div>
                <div class="header-tab" :class="{ 'header-tab_active': editType == 'more' }" v-if="showMoreInfo" @click="handlerEditTypeChange('more')">更多信息</div>
            </div>
        </global-page-back>
        <!-- 详情界面 -->
        <div v-if="pageType == 'view'" class="view">
                <div style="display: flex;justify-content: center;margin-top: 10px;">
                    <!-- 锚点 -->
                    <detail-anchor :basicForm="basicForm"  :tabList="tabList"  :avatar="avatar" @changeNavs="changeNavs"></detail-anchor>
                    <view-page
                        :activeItem="activeItem"
                        :basicForm="basicForm"
                        :basicRules="basicRules"
                        :pageType="pageType"
                        :avatar="avatar"
                        :tabList="tabList"
                        :exportDialog="exportDialog"
                        :archivesCompletenessUrl="archivesCompletenessUrl"
                        :oriBasicForm="oriBasicForm"
                        :detailFormShow="detailFormShow"
                        :schoolId="schoolId"
                        @desensitizeGet="desensitizeGet"
                        @getTemplateList="getTemplateList"
                        @firstExportBtn="firstExportBtn"
                    />
                </div>
        </div>

        <!-- 编辑界面 -->
        <div v-else class="edit common-form">
            <!-- 编辑-基本信息 -->
            <div v-if="editType == 'base'" class="common-form-layout">
                <div class="common-form-wrapper">
                    <!-- 锚点 -->
                    <form-anchor :anchorList="anchorList"></form-anchor>
                    <!-- 表单 -->
                    <edit-page
                        ref="editPage"
                        :basicForm="basicForm"
                        :basicRules="basicRules"
                        :pageType="pageType"
                        :editFlag = 'editFlag'
                        :action="action"
                        :headers="headers"
                        :beforeBasicAvatarUpload="beforeBasicAvatarUpload"
                        :handleBasicAvatarSuccess="handleBasicAvatarSuccess"
                        :avatar="avatar"
                        :showCourseSelection="showCourseSelection"
                        :isHasStu="isHasStu"
                        :item="item"
                        :courseSelectionList="courseSelectionList"
                        :submitFormLoading="submitFormLoading"
                        :archivesCompleteness="archivesCompleteness"
                        :triangleUp="triangleUp"
                        :des="des"
                        :OnlyNoMax="OnlyNoMax"
                        :triangleDown="triangleDown"
                        :oriBasicForm="oriBasicForm"
                        :detailFormShow="detailFormShow"
                        :schoolId="schoolId"
                        :headImgInfo="headImgInfo"
                        @changeCascader="changeCascader"
                        @stuNoChange="stuNoChange"
                        @clickCourseSelection="clickCourseSelection"
                        @basicReset="basicReset"
                        @submitBasicForm="submitBasicForm"
                        @showStowDetailForm="showStowDetailForm"
                        @getTeacherBaseInfo="getTeacherBaseInfo"
                        @setHeadImgInfo="setHeadImgInfo"
                    />
                </div>
                <!-- 底部按钮 -->
                <form-submit-btn>
                    <div slot="formSubmitBtn" class="basic-btn-div">
                        <el-button class="btn btn-reset" @click="basicReset">重置</el-button>
                        <el-button
                            class="btn btn-confirm"
                            type="primary"
                            :loading="submitFormLoading"
                            @click="submitBasicForm"
                        >确定</el-button>
                    </div>
                </form-submit-btn>
            </div>
            <!-- 编辑-更多信息 -->
            <div v-else class="common-form-layout">
                <div class="common-form-wrapper">
                    <!-- 锚点 -->
                    <form-anchor :anchorList="editMoreAnchorList" @handlerFormScroll="editMoreAnchorClick"></form-anchor>
                    <!-- 表单 -->
                    <edit-page-more
                        :pageType="pageType"
                        :schoolId="schoolId"
                        :oriBasicForm="oriBasicForm"
                        :templateList="editMoreAnchorList"
                        :selectedTemplate="editMoreSelectedTemplate"
                        @getFormRules="editMoreGetFormRules"
                    ></edit-page-more>
                </div>
            </div>
        </div>

        <!-- 学生档案排序弹窗 -->
        <el-dialog
            title="导出学生档案"
            :visible.sync="exportDialog"
            width="640px"
            :modal-append-to-body="false"
            center>
            <div class="exportDialog">
                <div class="exportDialog-title">
                    <div class="exportDialog-text">自定义导出内容</div>
                    <div class="exportDialog-reset" @click="resetExport">重置</div>
                </div>
                <div class="exportDialog-content">
                    <div class="exportDialog-content-title">
                        <div class="exportDialog-content-sort">排序</div>
                        <div class="exportDialog-content-name">模块名称</div>
                    </div>
                    <div class="exportDialog-content-title-sort">
                        <div class="exportDialog-content-sort-title">1</div>
                        <div class="exportDialog-content-name-text">基础信息</div>
                    </div>
                    <draggable :list="sortPdfList">
                        <transition-group>
                            <div
                                v-for="(item, index) in sortPdfList"
                                :key="item.id"
                                class="exportDialog-list">
                                <div class="exportDialog-sort">{{ index + 2 }}</div>
                                <div class="exportDialog-name">
                                    <div class="exportDialog-name-info">{{ item.archivesName }}</div>
                                    <div class="exportDialog-icon">
                                        <img :src="moveUrl" class="move-icon" />
                                        <img :src="delUrl" class="del-icon" @click="delDra(item)" />
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
                <div class="exportDialog-info">
                    <img class="dialog-info-icon" :src="infoUrl" />
                    <div class="dialog-info-text">导出的pdf会根据自定义排序的顺序进行展示</div>
                </div>
                <div class="dialog-footer">
                    <div class="dialog-submit" @click="previewPdf()">导出pdf</div>
                    <div class="dialog-cancel" @click="exportDialog = false">取消</div>
                </div>
            </div>
        </el-dialog>
        <StuExportDialog
            v-if="pageType == 'view'"
            :previewPdfList="previewPdfList"
            :exportStuInfoDialog="exportStuInfoDialog"
            :basicForm="basicForm"
            @closeStuDialog="closeStuDialog"
        />
    </div>
</template>

<script>
import { debounce } from "common-local";
import draggable from "vuedraggable";
import { getToken } from "@/libs/auth";
import GlobalPageBack from '@/components/scrollWrapper/Sub/GlobalPageBack/index.vue';
import ViewPage from "./SummaryStudentInfo/ViewPage.vue"
import FormAnchor from "@/components/scrollWrapper/Sub/FormAnchor/index.vue";
import DetailAnchor from "@/components/scrollWrapper/Sub/FormAnchor/DetailAnchor.vue";
import anchorList from "@/assets/data/SummaryStudent/anchorList.js";
import { formRules, changeRules } from "@/assets/data/SummaryStudent/formRules.js";
import { getNewRules } from "@/operations/summaryStudent.js";
import formRequiredFields from "@/libs/formRule.js";
import EditPage from "./SummaryStudentInfo/EditPageBase.vue"
import EditPageMore from "./SummaryStudentInfo/EditPageMore.vue"
import FormSubmitBtn from "@/components/scrollWrapper/Sub/FormSubmitBtn/index.vue";
import StuExportDialog from "@/components/scrollWrapper/SummaryStudentInfo/SummaryStudentInfoDialog";
import { listToTree } from "@/libs/utils.js";
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';

export default {
    name: "SummaryStudentInfo",
    components: {
        draggable,
        GlobalPageBack,
        ViewPage,
        FormAnchor,
        DetailAnchor,
        EditPage,
        EditPageMore,
        FormSubmitBtn,
        StuExportDialog,
    },
    props: {},
    data() {
        return {
            activeItem: null,
            editFlag:'',
            pageType: "add",
            addSave: false,
            editType: "base",
            schoolId: "",
            showCourseSelection: false,
            oriBasicForm: {}, // 重置用
            basicForm: {
                isNeedFaceLibrary: "",
                stuImg: "",
                studentName: "",
                remark: "",
                id: "",
                schoolId: "",
                idCard: "",
                studyNo: "",
                onlyCode: "",
                status: 1,
                sex: "1",
                classType: 1,
                organId: "",
                courseSelection: "",
                serialNumber: "",
                schoolNum: "",
                studentType: "",
                consumNumber:""
            },
            isHasStu: false,
            courseSelectionList: [],
            basicRules: {
                studentName: [
                    {
                        required: true,
                        message: "请输入学生姓名",
                        trigger: ["change", "blur"],
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: ["change", "blur"],
                    },
                ],
                onlyCode: [
                    {
                        required: true,
                        message: "请输入唯一号",
                        trigger: ["change", "blur"],
                    },
                    // {
                    //     validator: (rule, value, callback) => {
                    //         if (!/^[A-Za-z0-9]{9,}$/.test(value)) {
                    //             callback('唯一号只能包含数字和字母,长度最少9位');
                    //         } else {
                    //             callback();
                    //         }
                    //     },
                    //     trigger: ['blur']
                    // }
                ],
                // studyNo: [{
                //     required: true,
                //     message: '请输入学号',
                //     trigger: ['change', 'blur']
                // }, {
                //     validator: (rule, value, callback) => {
                //         if (!/^[A-Za-z0-9]+$/.test(value)) {
                //             callback('学号必须包含字母和数字');
                //         } else {
                //             callback();
                //         }
                //     },
                //     trigger: 'blur'
                // }],
                // idCard: [{
                //     required: true,
                //     message: '请输入身份证号',
                //     trigger: ['change', 'blur']
                // }, {
                //     validator: (rule, value, callback) => {
                //         if (!/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
                //             value)) {
                //             callback('身份证号不正确');
                //         } else {
                //             callback();
                //         }
                //     },
                //     trigger: 'blur'
                // }],
                organId: [{
                    required: true,
                    message: "请输入班级",
                    trigger: ["change", "blur"],
                }],
            },
            item: {
                label: "",
                placeholder: "班级",
                type: "cascader",
                value: "",
                list: [],
                cascaderProps: {
                    label: "name",
                    multiple: false,
                    value: "id",
                    checkStrictly: false,
                },
                disabled: false,
                filter(data) {
                    return data.organType != 1;
                },
                width: '394px',
            },
            treeData: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: true,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                showAlways: true,
                filter(data) {
                    return data.organType != 1;
                },
            },
            submitFormLoading: false,
            detailFormShow: true, // 更多学生档案展示
            exportDialog: false, // 学生档案导出
            sortPdfList: [], //pdf排序
            initSortList: [], //初始化pdf排序数据
            previewPdfList: {
                schoolId: "",
                peopleId: "",
                archivesTypeIds: [],
            }, //预览pdf数据传参
            exportStuInfoDialog: false,
            archivesCompleteness: 0,
            formData: {},
            listQuery: {},
            anchorList,
            formRules,
            changeRules,
            editMoreAnchorList: [],
            editMoreSelectedTemplate: -1,
            showMoreInfo:false,//是否显示更多信息-->如果更多信息中没有数据（档案模板）则隐藏更多信息
            OnlyNoMax:{},
            tabList:[
                {
                    id: 0,
                    title: "基础档案",
                    sel: true,
                    vHasPermi: ["studentBase:documentStu"]
                },
                {
                    id: 1,
                    title: "学生成绩",
                    sel: false,
                    vHasPermi: ["studentBase:score"]
                },
                {
                    id: 2,
                    title: "德育量化",
                    sel: false,
                    vHasPermi: ["studentBase:moral"]
                },
                {
                    id: 3,
                    title: "请假记录",
                    sel: false,
                    vHasPermi: ["studentBase:leave"]
                }
            ],
            headImgInfo: {
                auditState:'2',
                tip:'照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰'
            }
        };
    },
    filters: {},
    computed: {
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
        avatar() {
            return require('@/assets/images/imgErrorAdr.png')
        },
        triangleUp() {
            return require("@/assets/images/stuInfoTemplate/triangle-up.png");
        },
        des () {
            return require('@/assets/images/des.png')
        },
        triangleDown() {
            return require("@/assets/images/stuInfoTemplate/triangle-down.png");
        },
        archivesCompletenessUrl() {
            return require("@/assets/images/archivesCompleteness.png");
        },
        delUrl() {
            return require("@/assets/images/school-info-del.png");
        },
        infoUrl() {
            return require("@/assets/images/school-info-icon.png");
        },
        moveActiveUrl() {
            return require("@/assets/images/school-info-move.png");
        },
        moveUrl() {
            return require("@/assets/images/school-info-moveN.png");
        },
    },
    watch: {
        basicForm: {
            handler() {
                this.handlerFormRequiredFields();
            },
            deep: true,
        },
    },
    created() {
        this.init()
    },
    mounted() {
        this.getOrg()
        this.$eventDispatch('setGlobalPageType', 'form')
        let item = this.$route.query.item;
        if (item) {
            this.changeNavs(item);
        }
    },
    activated() {
        this.$eventDispatch('setGlobalPageType', 'form')
    },
    methods: {
        init() {
            this.tabList = tabPageHasAuth(this.tabList);
            // console.log("[init]this.$route.query:", this.$route.query)
            this.pageType = this.$route.query.pageType;
            this.schoolId = this.$route.query.schoolId;
            this.getOnlyNoMax(this.schoolId)
            if (this.$route.query.basicForm) {
                Object.assign(
                    this.basicForm,
                    JSON.parse(this.$route.query.basicForm),
                );
                this.oriBasicForm = JSON.parse(this.$route.query.basicForm);
                console.log("oriBasicForm", this.oriBasicForm);
                if (this.oriBasicForm.organId) {
                    this.item.value = this.oriBasicForm.organId
                    // console.log(this.item, "this.item.value")
                }
            }
            if (this.pageType == "edit" ) {
                this.item.disabled = true;
                this.getHeadImgByType();
                // console.log(this.item.disabled,'this.item.disabled')
            }

            //编辑回显
            if (this.pageType == "edit" && this.basicForm.idCard) {
                this.basicForm.idCard = this.basicForm._idCard;
                if (this.basicForm.idCard.indexOf("*") !== -1) {
                    this.desensitizeGet();
                }
            }
            console.log(this.$route.query.formData, this.$route.query.listQuery);
            this.formData = this.$route.query.formData; // 保存当前查询条件
            this.listQuery = this.$route.query.listQuery; // 保存当前查询条件
            this.handlerFormRequiredFields();
            this.editMoreGetTemplateList();
        },
        //获取当前学校最大唯一号
		getOnlyNoMax(id){
			this._fet('/school/schoolStudent/getSchoolMaxOnlyCode', {
				schoolId: id,
			}).then((res) => {
				if (res.data.code === "200") {
					this.OnlyNoMax = res.data.data;
				} else if (res.data && res.data.msg) {
					this.$message.error(res.data.msg)
				}
			});
		},
        /**
         * @Description: 全局表单必填项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-19 11:08:55
         */
        handlerFormRequiredFields() {
            const getRules = getNewRules();

            if (this.basicForm.sex) {
                this.formRules.positionInfo = getRules[this.basicForm.sex]();
            }

            this.anchorList = formRequiredFields(
                this.anchorList,
                this.formRules,
                this.basicForm,
            );
            console.log(this.formRules.positionInfo, "ces");
        },
        /**
         * @Description:班级改变
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-17 11:19:35
         */
        changeCascader(Cascader) {
            console.log("changeCascader", Cascader)
            if (Cascader.data.value.length == 0) {
                this.basicForm.organId = ""
                return
            }
            if (!Cascader.data.value[1]) {
                // this.$message.error("请选择班级");
                return
            }
            this.basicForm.organId = Cascader.data.value.slice(-1)[0]
        },
        /**
         * @Description: 获取学生档案部门下拉列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-06-20 09:23:08
         */
        getOrg() {
            let obj = { schoolId: this.schoolId, jobScope: ["1", "2", "3"], onlyClassType1: 1 };
            this._fet(
                // "/school/schoolOrgan/listByCondition",
                "/school/schoolOrgan/listAuthByCondition",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    const treeData = listToTree(res.data.data.list, {
                        parentKey: "parentOrg",
                    });
                    this.treeData.dataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    console.log(
                        this.treeData.dataList,
                        "this.treeData.dataList",
                    );
                    let jsonList = JSON.stringify(this.treeData.dataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.item.list = JSON.parse(jsonData);
                    this.cleanTree(this.item.list);
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
            this.loadingShow = false;
        },
        /**
         * @Description: 递归，把没有班级的年级过滤
         * @Author: 闫乔
         * @Date: 2024-08-23 16:54:27
         * @param {*} data
         */        
        cleanTree(data) {
            // 遍历数据
            for (let i = 0; i < data.length; i++) {
                // 如果当前节点的organType不是4，则进行递归操作
                if (data[i].organType != '4') {
                    // 如果当前节点的children为空，则直接删除
                    if (!data[i].children || data[i].children.length === 0) {
                        data.splice(i, 1);
                        i--; // 因为数组长度变化，所以需要重新检查当前位置
                    } else {
                        // 对子节点进行递归操作
                        this.cleanTree(data[i].children);
                        // 如果子节点清理后，children为空，则删除当前节点
                        if (!data[i].children || data[i].children.length === 0) {
                            data.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
            return data;
        },
        desensitizeGet() {
            if (this.basicForm.idCard.indexOf("*") !== -1) {
                this._fet(
                    `/school/schoolStudent/getStudentIdCard/${this.basicForm.id}`,
                    {
                        id: this.basicForm.id,
                    },
                ).then((res) => {
                    if (res.data.code === "200") {
                        this.basicForm.idCard = res.data.data;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            } else {
                this.basicForm.idCard = `${this.basicForm.idCard.slice(
                    0,
                    3,
                )}***********${this.basicForm.idCard.slice(-4)}`;
            }
        },
        back() {
            console.log(this.$route,'路由信息')
            this.$eventDispatch('setBreadcrumbs', this.$route)
            this.$router.push({
                name: "SummaryStudent",
                query: {
                    formData: this.formData, // 保存当前查询条件
                    listQuery: this.listQuery, // 保存当前查询条件
                },
            });
            this.$eventDispatch('setGlobalPageType', 'list')
        },
        /**
         * @Description: 修改编辑状态模式
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: WGS
         * @Date: 2023-07-25 15:33:08
         */
        handlerEditTypeChange(type) {
            if (type == 'more') {
                if (this.pageType == 'add' && this.editType == 'base' && !this.addSave) {
                    return this.$message.info('请保存基础信息～')
                }
                this.editMoreGetTemplateList()
            }
            this.editType = type;
        },
        getTeacherBaseInfo() {
            this.back()
        },
        closeStuDialog() {
            this.exportStuInfoDialog = false;
        },
        /**
         * @Descripttion: 获取教师档案更多模板数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-06 09:20:05
         * @param {*} value
         */
        getTemplateList(value) {
            // console.log(value,'valuevaluevaluevalue');
            this.initSortList = JSON.parse(JSON.stringify(value));
            this.sortPdfList = JSON.parse(JSON.stringify(value));
        },
        /**
         * @Description: 切换导航
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-18 09:52:36
         * @param {*} item
         */
        changeNavs(item) {
            this.activeItem = {...this.$route.query,item};
            this.tabList.forEach((e) => {
                if (e.id === item.id) {
                    e.sel = true;
                } else {
                    e.sel = false;
                }
            });
        },
        /**
         * @Descripttion: 删除导出列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2022-12-29 15:25:23
         * @param {*} v
         */
        delDra(v) {
            let list = this.sortPdfList;
            list = list.filter((item) => {
                return v.archivesName !== item.archivesName;
            });
            this.sortPdfList = list;
        },
        /**
         * @Descripttion: 重置导出列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2022-12-29 15:25:52
         */
        resetExport() {
            this.sortPdfList = JSON.parse(JSON.stringify(this.initSortList));
        },
        firstExportBtn(type) {
            this.exportDialog = type;
            this.resetExport();
        },
        /**
         * @Descripttion:预览pdf
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-06 09:48:00
         */
        previewPdf() {
            this.previewPdfList = {
                schoolId: "",
                peopleId: "",
                archivesTypeIds: [],
            };
            this.sortPdfList.map((item) => {
                this.previewPdfList.archivesTypeIds.push(item.id);
            });
            this.previewPdfList.schoolId = this.$route.query.schoolId;
            this.previewPdfList.peopleId = this.oriBasicForm.id;
            this.exportStuInfoDialog = true;
            console.log(this.previewPdfList, "previewPdfList");
            console.log("点击预览了");
        },

        // 简单深拷贝
        cloneDeep(data) {
            return JSON.parse(JSON.stringify(data));
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        // 上传校验
        beforeBasicAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            if (!is50) {
                this.$message.error("上传文件名称不能超过50个字符!");
            }
            if (!isJPG && !isPNG) {
                this.$message.error("上传LOGO必须是JPG或PNG格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传LOGO图片大小不能超过20M!");
            }
            return (isJPG || isPNG) && isLt2M && is50;
        },
        handleBasicAvatarSuccess(res, file) {
            const url = res.data.url;
            let src = url.replace(
                "http://192.168.0.241:9000/example",
                "http://124.71.235.241:9000/example",
            );
            this.basicForm.stuImg = src;
            console.log(
                "[handleBasicAvatarSuccess]this.basicForm.stuImg:",
                this.basicForm.stuImg,
            );
        },
        stuNoChange(data) {
            if (data !== "") {
                this._fet("/school/schoolStudent/getStudentByStudyNo", {
                    schoolId: this.schoolId,
                    studyNo: data,
                }).then((res) => {
                    this.handleRes(res, () => {
                        Object.keys(this.basicForm).forEach((key) => {
                            if (res.data.data.length > 0 && key !== "id") {
                                this.basicForm[key] =
                                    res.data.data[0][key] !== undefined
                                        ? res.data.data[0][key]
                                        : this.basicForm[key];
                            }
                        });
                        this.isHasStu = res.data.data.length > 0;
                    });
                });
            } else {
                this.isHasStu = false;
            }
        },
        clickCourseSelection() {
            this.$router.push({
                name: "SystemDict",
                query: {
                    dictKey: "courseSelection",
                },
            });
        },
        // 基础信息-重置
        basicReset() {
            this.basicForm = JSON.parse(JSON.stringify(this.oriBasicForm));
            if (this.pageType == "edit" && this.basicForm.idCard) {
                this.basicForm.idCard = this.basicForm._idCard;
                if (this.basicForm.idCard.indexOf("*") !== -1) {
                    this.desensitizeGet();
                }
            }
            console.log("[重置]this.basicForm:", this._.cloneDeep(this.basicForm))
        },
        // 基础信息-提交
        submitBasicForm: debounce(function () {
            this.submitFormLoading = true;
            this.$refs.editPage.$refs.basicForm.validate((valid) => {
                if (valid) {
                    if (this.basicForm.organId) {
                        this.$delete(this.basicForm,"headImgPass")
                        this.addNewSector(this.basicForm);
                    } else {
                        this.$message.error("请选择班级");
                        this.submitFormLoading = false;
                    }
                } else {
                    this.submitFormLoading = false;
                    return false;
                }
            });
        }, 300),
        addNewSector(form) {
            this._fet("/school/schoolStudent/save", form).then((res) => {
                this.handleRes(res, () => {
                    this.$message.success("成功");
                    this.isHasStu = false;
                    this.submitFormLoading = false;
                    this.oriBasicForm = res.data.data;
                    // -----
                    // if(form.consumNumber){
                    //     this.editFlag = true
                    // }
                    this.$refs.editPage.changeFlag()

                    this.archivesCompleteness = this.oriBasicForm.archivesCompleteness;
                    if (this.pageType == 'add') this.addSave = true
                    console.log(
                        "[addNewSector]this.oriBasicForm",
                        this.oriBasicForm,
                    );
                });
                if (res.data.code != "200") {
                    this.submitFormLoading = false;
                }
            }).catch(() => {
                this.submitFormLoading = false;
            });
        },
        // 展示或隐藏档案表单
        showStowDetailForm() {
            this.detailFormShow = !this.detailFormShow;
        },

        // 编辑模式更多信息标签页信息获取模板列表
        async editMoreGetTemplateList() {
            console.log("[获取模板列表]this.type", this.type)
            this._fet("/school/schoolArchivesType/listByCondition", {
                schoolId: this.schoolId,
                archivesPersonType: "1" // 档案应用人类型 1学生档案 2教师档案
            }).then(res => {
                // console.log("[editMoreGetTemplateList]res:", res)
                this.handleRes(res, async () => {
                    let templateList = res.data.data
                    templateList = templateList.map(e => {
                        e.title = e.archivesName
                        e.rules = ''
                        e.text = '',
                        e.finish = false,
                        e.key = '#J_studentInfo_more_info'
                        e.change = true
                        e.active = false
                        e.mode = this.pageType
                        return e
                    })
                    this.editMoreAnchorList = templateList
                    console.log("[editMoreGetTemplateList]this.editMoreAnchorList:", this.editMoreAnchorList)
                    if(this.editMoreAnchorList.length > 0) {
                        this.showMoreInfo = true
                        this.editMoreSelectedTemplate = 0
                        this.$set(this.editMoreAnchorList[this.editMoreSelectedTemplate], 'active', true)
                    }else{
                        this.showMoreInfo = false
                    }
                })
            })
            console.log(this.showMoreInfo,'是否显示更多信息');
        },
        // 编辑模式更多信息锚点点击
        editMoreAnchorClick(item) {
            console.log("[editMoreAnchorClick]", item)
            this.$set(this.editMoreAnchorList[this.editMoreSelectedTemplate], 'active', false)
            this.editMoreSelectedTemplate = this.editMoreAnchorList.findIndex(e => item.id == e.id)
            this.$set(this.editMoreAnchorList[this.editMoreSelectedTemplate], 'active', true)
        },
        /**
         * @Description: 更多信息表单必填项教研
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-27 14:55:53
         */
        editMoreGetFormRules: debounce(function (data) {
            let formList = this.getAllFormItem(data),
                str = Object.prototype.toString,
                arrType = '[object Array]',
                strType = 'string',
                num = 0,
                requiredLen = formList.filter(item => item.required && item.show).length;
            formList.forEach((item) => {
                if(item.required && str.call(item.value) === arrType && item.value.length > 0) {
                    console.log(item,'item-array')
                    num++;
                }

                if (item.required && typeof item.value === strType && item.value) {
                    console.log(item,'item-string')
                    num++;
                }
            })
            console.log(requiredLen,'===')
            if (requiredLen - num > 0) {
                this.editMoreAnchorList[this.editMoreSelectedTemplate].text = `${requiredLen - num}必填项未填`
                this.editMoreAnchorList[this.editMoreSelectedTemplate].finish = false;
            } else {
                this.editMoreAnchorList[this.editMoreSelectedTemplate].finish = true;
            }
        }, 200),
        getAllFormItem (data) {
            let formList = [];
            data.forEach((item) => {
                if (item.list && item.list.length > 0) {
                    formList = formList.concat(...this.getAllFormItem(item.list))
                } else {
                    formList.push(item)
                }
            })
            return formList;
        },
        setHeadImgInfo(obj) {
            this.headImgInfo = obj;
         },
         async getHeadImgByType() {
            if(this.pageType == 'add') {
                return
            }
            let obj = {
                personType: 'student',
                personId: this.basicForm.id
            }
            let res = await this._fet('/school/common/getHeadImgByType', obj)
            if(res.data.code == '200') {
                let data = res.data.data
                this.headImgInfo.auditState = data.headImgPass || "";
                this.headImgInfo.tip = data.headImgMessage || "";
            }
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep .global-page-back {
    .back-wrapper {
        flex-shrink: 0;
    }

    .tab-list {
        width: 100%;

        .header-tabs {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .header-tab {
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #606C80;
                cursor: pointer;

                +.header-tab {
                    margin-left: 35px;
                }
            }

            .header-tab_active {
                position: relative;
                font-weight: bold;
                color: #2B2E33;
            }
            .header-tab_active:after {
                position: absolute;
                left: 15px;
                bottom: -12px;
                content: "";
                width: 30px;
                height: 3px;
                background: #3C7FFF;
                border-radius: 2px;
            }
        }
    }
}

/* 查看-导出 */
.exportDialog {
    margin-top: 10px;

    .exportDialog-title {
        display: flex;
        align-items: center;

        .exportDialog-text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #363a40;
            margin-right: 16px;
        }

        .exportDialog-reset {
            cursor: pointer;
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #e8f1fc;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3C7FFF;
        }
    }

    .exportDialog-content {
        width: 544px;
        background: #ffffff;
        border: 1px solid #edeff2;
        border-radius: 2px;
        margin-top: 20px;

        .exportDialog-content-title {
            display: flex;
            line-height: 40px;
            background: #f5f7fa;

            .exportDialog-content-sort {
                width: 80px;
                border-right: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
            }
        }
        .exportDialog-content-title-sort {
            display: flex;
            line-height: 40px;
            .exportDialog-content-sort-title {
                width: 80px;
                border-right: 1px solid #edeff2;
                border-bottom: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name-text {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
                border-bottom: 1px solid #edeff2;
            }
        }
    }

    .exportDialog-list {
        display: flex;
        background-color: #fff;
        height: 40px;
        line-height: 40px;

        .exportDialog-sort {
            width: 80px;
            height: 40px;
            line-height: 40px;
            border-right: 1px solid #edeff2;
            border-bottom: 1px solid #edeff2;
            text-align: center;

            &:last-child {
                border-bottom: 0;
            }
        }

        .exportDialog-name {
            flex: 1;
            height: 40px;
            line-height: 40px;
            display: flex;
            border-bottom: 1px solid #edeff2;
            position: relative;

            .exportDialog-name-info {
                margin-left: 20px;
            }

            .exportDialog-icon {
                position: absolute;
                right: 20px;
                top: 14px;
                display: flex;

                .move-icon {
                    cursor: pointer;
                    width: 14px;
                    height: 14px;
                    margin-right: 26px;
                    display: block;
                }

                .del-icon {
                    cursor: pointer;
                    width: 12px;
                    height: 12px;
                    display: block;
                }
            }
        }
    }

    .exportDialog-info {
        margin-top: 20px;
        height: 14px;
        line-height: 14px;
        display: flex;

        .dialog-info-icon {
            width: 13px;
            height: 13px;
        }

        .dialog-info-text {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f56c6c;
        }
    }

    .dialog-footer {
        display: flex;
        // margin: 0 auto;
        margin-top: 26px;

        .dialog-cancel,
        .dialog-submit {
            cursor: pointer;
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border: 1px solid #3C7FFF;
            border-radius: 4px;
            color: #3C7FFF;
        }

        .dialog-submit {
            background: #3C7FFF;
            color: #ffffff;
            margin: 0 24px 0 140px;
        }
    }
}
</style>
